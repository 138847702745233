import { Suspense, lazy, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Loader } from '@plot/ui';

import { useWorkspace } from '@/lib/contexts';

const PlotMap = lazy(() => import('../../features/plot-map'));
const Drawers = lazy(() => import('../drawers'));

function checkPathnameEnding(str: string): boolean {
  const endings = ['/profile'];
  return endings.some((ending) => str.endsWith(ending));
}

export function Workspace() {
  const { workspace, workspaces } = useWorkspace();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (checkPathnameEnding(pathname)) return;
    const noWorkspaces = workspaces?.length === 0;
    const isWorkspaceSuspended = workspace?.isSuspended;

    if (noWorkspaces) {
      navigate(`/workspaces/inactive`);
      return;
    }

    if (isWorkspaceSuspended) {
      navigate(`/workspaces/suspended`);
      return;
    }

    if (workspace) {
      if (!workspace.isSetupComplete && workspace.role === 'owner') {
        navigate(`/workspaces/${String(workspace.id)}/setup`);
        return;
      }
    }
  }, [pathname, workspace, workspaces]);

  return (
    <>
      <Suspense fallback={<Loader backdrop cover />}>
        <PlotMap />
      </Suspense>
      <Suspense fallback={null}>
        <Drawers />
      </Suspense>
    </>
  );
}

export default Workspace;
