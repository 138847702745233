import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { MapboxGeoJSONFeature } from 'mapbox-gl';

import { Point } from '@plot/geonorge-api';
import {
  GeodataAreaDto,
  GeodataProjectStageDto,
  GeodataUnitDto,
  type AreaDto,
  type AreaReportDto,
  type CancelSubscriptionDto,
  type WorkspaceDto,
} from '@plot/plot-api';

import { PropertyData } from '@/features/plot-map/components/popover-features';
import { FeatureContext } from '@/lib/types';

export enum Drawer {
  AREA_REPORTS = 'AREA_REPORTS',
  MAP_LAYERS = 'MAP_LAYERS',
  INSIGHTS_DRAWER = 'INSIGHTS_DRAWER',
}

export enum Modal {
  CREATE_WORKSPACE = 'CREATE_WORKSPACE',
  DELETE_WORKSPACE = 'DELETE_WORKSPACE',
  LEAVE_WORKSPACE = 'LEAVE_WORKSPACE',
  CREATE_REPORT = 'CREATE_REPORT',
  EDIT_REPORT = 'EDIT_REPORT',
  DELETE_REPORT = 'DELETE_REPORT',
  EDIT_AREA = 'EDIT_AREA',
  DELETE_AREA = 'DELETE_AREA',
  FEEDBACK = 'FEEDBACK',
  INVITE_MEMBER = 'INVITE_MEMBER',
  REMOVE_MEMBER = 'REMOVE_MEMBER',
  DELETE_BILLING_ACCOUNT = 'DELETE_BILLING_ACCOUNT',
  REMOVE_SUBSCRIPTION = 'REMOVE_SUBSCRIPTION',
  RESUME_SUBSCRIPTION = 'RESUME_SUBSCRIPTION',
  PROJECT_DETAILS = 'PROJECT_DETAILS',
  UNIT_DETAILS = 'UNIT_DETAILS',
  PLOT_DETAILS = 'PLOT_DETAILS',
}

export enum DrawMode {
  DRAW_POLYGON = 'draw_polygon',
  SIMPLE_SELECT = 'simple_select',
  DIRECT_SELECT = 'direct_select',
  STATIC = 'static',
}

export enum OnTheFlyDrawState {
  DISABLED = 'disabled',
  ENABLED_DRAWING = 'enabled_drawing',
  ENABLED_ACTIVE = 'enabled_active',
  ENABLED_INACTIVE = 'enabled_inactive',
}

export type DrawerState = {
  id: keyof typeof Drawer;
  ctx?: FeatureContext | Point | null;
};

export type CancelSubscriptionType = CancelSubscriptionDto & {
  id?: string;
  name: string;
};

export type ProjectDetailsContext = FeatureContext;

export type ModalCtx =
  | CancelSubscriptionType
  | WorkspaceDto
  | AreaReportDto
  | AreaDto
  | ProjectDetailsContext;

export type ModalState = {
  id: keyof typeof Modal;
  ctx?: ModalCtx;
};

type PopoverData = {
  type: string;
  data: GeodataProjectStageDto[] | GeodataUnitDto[];
  feature: MapboxGeoJSONFeature | PropertyData;
  origin?: 'search' | 'click';
  isInMap?: boolean;
};

export type State = {
  drawMode: DrawMode;
  isDrawingOnTheFly: boolean;
  isRotatingMap: boolean;
  onTheFlyDrawState: OnTheFlyDrawState;
  onTheFlyDrawGeometry: string | null;
  searchedArea: GeodataAreaDto | null;
  searchInput?: string;
  userDefinedArea?: number | null;
  workspace: WorkspaceDto | null;
  report: AreaReportDto | null;
  showReports: boolean;
  drawer: DrawerState | null;
  modal: ModalState | null;
  excludeGaragePricesId: boolean;
  popoverData?: PopoverData | null | undefined;
  popoverCoordinates?: number[] | undefined | null;
};

const defaultState: State = {
  drawMode: DrawMode.SIMPLE_SELECT,
  isDrawingOnTheFly: false,
  isRotatingMap: false,
  onTheFlyDrawGeometry: null,
  onTheFlyDrawState: OnTheFlyDrawState.DISABLED,
  searchedArea: null,
  userDefinedArea: null,
  searchInput: '',
  excludeGaragePricesId: false,
  popoverData: null,
  popoverCoordinates: null,
  workspace: null,
  showReports: false,
  report: null,
  drawer: null,
  modal: null,
};

//Data to be stored in the localstorage
const sensitiveData: Array<keyof State> = [];

type Action =
  | { type: 'setWorkspace'; payload: WorkspaceDto | null }
  | { type: 'setReport'; payload: AreaReportDto | null }
  | { type: 'setShowReports'; payload: boolean }
  | { type: 'cleanDrawnAreas' }
  | { type: 'toggleDrawMode' }
  | { type: 'setOnTheFlyDrawState'; payload: OnTheFlyDrawState }
  | { type: 'setOnTheFlyDrawGeometry'; payload: string | null }
  | { type: 'setIsRotatingMap'; payload: boolean }
  | { type: 'setSearchedArea'; payload: GeodataAreaDto | null }
  | { type: 'setUserDefinedArea'; payload: number | null }
  | { type: 'setSearchInput'; payload: string }
  | { type: 'setExcludeGaragePricesId'; payload: boolean }
  | {
      type: 'setPopoverCoordinates';
      payload: number[] | undefined | null;
    }
  | {
      type: 'setPopoverData';
      payload: PopoverData | null | undefined;
    }
  | { type: 'setDrawMode'; payload: DrawMode }
  | { type: 'openModal'; payload: ModalState }
  | { type: 'closeModal' }
  | { type: 'openDrawer'; payload: DrawerState }
  | { type: 'toggleDrawer'; payload: DrawerState }
  | { type: 'closeDrawer' }
  | { type: 'reset' };

function appReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'setOnTheFlyDrawGeometry':
      return {
        ...state,
        onTheFlyDrawGeometry: action.payload,
      };

    case 'setSearchedArea':
      return {
        ...state,
        searchedArea: action.payload,
      };

    case 'toggleDrawMode':
      return {
        ...state,
        drawMode:
          state.drawMode === DrawMode.DRAW_POLYGON
            ? DrawMode.SIMPLE_SELECT
            : DrawMode.DRAW_POLYGON,
      };

    case 'setOnTheFlyDrawState':
      return {
        ...state,
        onTheFlyDrawState: action.payload,
        isDrawingOnTheFly: action.payload !== OnTheFlyDrawState.DISABLED,
        onTheFlyDrawGeometry:
          action.payload === OnTheFlyDrawState.DISABLED
            ? null
            : state.onTheFlyDrawGeometry,
        searchedArea: null,
      };

    case 'setIsRotatingMap':
      return {
        ...state,
        isRotatingMap: action.payload,
      };

    case 'setUserDefinedArea':
      return {
        ...state,
        userDefinedArea: action.payload,
      };

    case 'setDrawMode':
      return {
        ...state,
        drawMode: action.payload,
      };

    case 'cleanDrawnAreas':
      return {
        ...state,
        searchedArea: defaultState.searchedArea,
        onTheFlyDrawGeometry: defaultState.onTheFlyDrawGeometry,
        isDrawingOnTheFly: defaultState.isDrawingOnTheFly,
        onTheFlyDrawState: defaultState.onTheFlyDrawState,
      };

    case 'setWorkspace':
      return {
        ...state,
        workspace: action.payload,
      };

    case 'setReport':
      return {
        ...state,
        report: action.payload,
      };

    case 'setShowReports':
      return {
        ...state,
        showReports: action.payload,
      };

    case 'openModal':
      return {
        ...state,
        modal: action.payload,
      };

    case 'closeModal':
      return {
        ...state,
        modal: null,
      };

    case 'openDrawer':
      return {
        ...state,
        drawer: action.payload,
      };

    case 'toggleDrawer':
      return {
        ...state,
        drawer: state.drawer?.id === action.payload.id ? null : action.payload,
      };

    case 'closeDrawer':
      return {
        ...state,
        drawer: null,
      };

    case 'setExcludeGaragePricesId':
      return {
        ...state,
        excludeGaragePricesId: action.payload,
      };

    case 'setPopoverData':
      return {
        ...state,
        popoverData: action.payload,
      };

    case 'setPopoverCoordinates':
      return {
        ...state,
        popoverCoordinates: action.payload,
      };

    case 'setSearchInput':
      return {
        ...state,
        searchInput: action.payload,
      };

    case 'reset':
      return defaultState;

    default:
      return state;
  }
}

type AppContextType = {
  state: State;
  dispatch: (action: Action) => void;
};

const AppContext = createContext<AppContextType | undefined>(undefined);

type AppProviderProps = {
  children: React.ReactNode;
};

function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === 'undefined' ? undefined : (JSON.parse(value ?? '') as T);
  } catch {
    console.log('JSON parse error on', { value });
    return undefined;
  }
}

export function AppProvider({ children }: AppProviderProps) {
  const readValue = useCallback((): State => {
    if (typeof window === 'undefined') {
      return defaultState;
    }

    try {
      const localStorageJson = window.localStorage.getItem('plot-app');
      if (localStorageJson) {
        const localStorage = parseJSON(localStorageJson);
        if (localStorage) {
          return {
            ...defaultState,
            ...localStorage,
          } as State;
        }
      }
      return defaultState;
    } catch (error) {
      console.warn(`Error reading localStorage:`, error);
      return defaultState;
    }
  }, []);

  const setValue = useCallback((value: State) => {
    if (typeof window === 'undefined') {
      console.warn(
        `Tried setting localStorage even though environment is not a client`
      );
    }

    try {
      const sensitiveDataToStore = Object.fromEntries(
        Object.entries(value).filter(([k]) =>
          sensitiveData.includes(k as keyof State)
        )
      );
      window.localStorage.setItem(
        'plot-app',
        JSON.stringify(sensitiveDataToStore)
      );
    } catch (error) {
      console.warn(`Error setting localStorage:`, error);
    }
  }, []);

  const [state, dispatch] = useReducer(appReducer, readValue());

  useEffect(() => {
    setValue(state);
  }, [setValue, state]);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
}

export function useApp() {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error('useApp must be used within an AppProvider');
  }

  return context;
}
